import React, { useState, useRef, useEffect } from 'react';

const DropdownMenu = ({ items = [], onSelect }: any) => {
    const [isOpen, setIsOpen] = useState(false);
    const [search, setSearch] = useState('');
    const dropdownRef: any = useRef(null); // Ref for the dropdown container
    const newItems = []
    for (const item of items) {
        if (['html', 'image', 'file', 'customlabel',"paneldynamic"].includes(item.type)) continue;
        switch (item.type) {
            case 'fullname': 
                if (item.prefixVisible) newItems.push({name: `${item.name} (Prefix)`, valueName: `{${item.name}.prefix}`});
                newItems.push({name: `${item.name} (First Name)`, valueName: `{${item.name}.firstName}`}) ;
                if (item.middleNameVisible) newItems.push({name: `${item.name} (Middle Name)`, valueName: `{${item.name}.middleName}`})
                newItems.push({name: `${item.name} (Last Name)`, valueName: `{${item.name}.lastName}`});
            break;
            case 'address':
                if (item.showStreetAddressLine1 == undefined) newItems.push({name: `${item.name} (St. Address 1)`, valueName: `{${item.name}.streetAddressLine1}`}) 
                if (item.showStreetAddressLine2 == undefined) newItems.push({name: `${item.name} (St. Address 2)`, valueName: `{${item.name}.streetAddressLine2}`}) 
                if (item.showCity == undefined) newItems.push({name: `${item.name} (City)`, valueName: `{${item.name}.city}`}) 
                if (item.showState == undefined) newItems.push({name: `${item.name} (State)`, valueName: `{${item.name}.state}`}) 
                if (item.showCountry == undefined) newItems.push({name: `${item.name} (Country)`, valueName: `{${item.name}.country}`}) 
                if (item.showPostalCode == undefined) newItems.push({name: `${item.name} (Postal Code)`, valueName: `{${item.name}.postalCode}`})
            break;
            // case 'customlabel':
            //     newItems.push({name: `${item.labelText || 'Label'}`, valueName: `${item.labelText || 'Label'}`})
            //     newItems.push({name: `${item.subLabelText || 'Sub Label'}`, valueName: `${item.subLabelText || 'Sub Label'}`})
            // break;
            default:
                newItems.push({name: `${item.name}`, valueName: `{${item.name}}`})
            break;
        }
    }
    const toggleDropdown = () => items.length && setIsOpen(!isOpen);

    const handleClickOutside = (event: any) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    const handleItemClick = (item: any) => {
        setIsOpen(false);
        onSelect && typeof onSelect == 'function' && onSelect(item);
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Filter items based on search input
    const filteredItems = newItems.filter((item: any) => item.name.toLowerCase().includes(search.toLowerCase()));

    return (
        <div className="dropdown" ref={dropdownRef}>
            <span onClick={toggleDropdown} className="dropdown-toggle">
                + Add Dynamic Field
            </span>

            {isOpen && (
                <div className="dropdown-menu">
                    <input 
                        type="text" 
                        placeholder="Search..." 
                        value={search}
                        onChange={(e) => setSearch(e.target.value)}
                        className="dropdown-search"
                    />
                    {
                        filteredItems.map((item: any, index: number) => (
                            <span key={index + 'drop-item'} onClick={() => handleItemClick(item)}>{item.name}</span>
                        ))
                    }
                </div>
            )}
        </div>
    );
};

export default DropdownMenu;
