
export const REQUIRED_ELEMENTS_TYPE = [
    "fullname",
    "emailfield",
    "phonenumber",
    "datepicker",
    "address",
    "termsconditions",
    "privacypolicy",
    "customlabel",
   // "panel",
    "paneldynamic",
    "html",
    "text",
    "comment",
    "monthfield",
    "image",
    "dropdown",
    "tagbox",
    "checkbox",
    "file",
    "boolean",
    "radiogroup"
]

export const PREFIX_ARRAY: any = [
	{ value: "mr", text: "MR" },
	{ value: "mrs", text: "Mrs" },
	{ value: "ms", text: "Ms" },
];
export const MONTH_ARRAY: any = [
	{ value: "January", text: "January" },
	{ value: "February", text: "February" },
	{ value: "March", text: "March" },
	{ value: "April", text: "April" },
	{ value: "May", text: "May" },
	{ value: "June", text: "June" },
	{ value: "July", text: "July" },
	{ value: "August", text: "August" },
	{ value: "September", text: "September" },
	{ value: "October", text: "October" },
	{ value: "November", text: "November" },
	{ value: "December", text: "December" },
];